<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Solving One Equation with One Unknown</h2>
      <p class="mb-3">
        If you have an equation that has a single unknown variable, you should be able to rearrange
        that equation in order to determine the value of said variable. The question is, how do you
        do these rearrangements. Let's take a look at the following equation.
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$18 = x + 4$" />
      </p>

      <p class="mb-3">
        The goal is to isolate the unknown variable on one side of the equation. To move terms from
        one side of the equation to the other, you perform the OPPOSITE operation. For example, if a
        term is added on one side, you subtract it from both sides to move it to the opposite side
        of the equation. Doing this to our previous equation, we get
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$18 - 4 = x + 4 - 4 \\ 14 = x$" />
      </p>

      <p class="mb-3">Let's consider the equation below that is slightly more complicated:</p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$3x + 6 = 30$" />
      </p>

      <p class="mb-3">
        This is similar to the last case, but now x is multiplied by a constant. Anytime you have
        multiple pieces that have to be moved to the other side, you always move the pieces that
        aren't directly associated with the unknown variable first. In this case, that means dealing
        with the 6 before we deal with the 3 that is multiplied by the x. This gives us:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$3x = 30 - 6 \\ 3x = 24$" />
      </p>

      <p class="mb-3">
        Now we only have the 3 to deal with. Since it is multiplied by the x, we need to divide both
        sides by 3 to isolate the x.
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\displaystyle{\frac{3x}{3}}=\displaystyle{\frac{24}{3}} \hspace{0.5cm} \to \hspace{0.5cm} x = 8$"
        />
      </p>

      <p class="mb-3">
        Using this knowledge, try and determine the value of x for each of the following
        expressions.
      </p>

      <p class="mb-3">
        <stemble-latex content="$\text{a)}\hspace{0.2cm} \displaystyle{\frac{x}{2}} - 7 =~$" />
        <number-value :value="expr1val" />
      </p>

      <calculation-input
        v-model="inputs.x1"
        class="mb-5"
        prepend-text="$\text{x:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{b)}\hspace{0.2cm} \displaystyle{\frac{6x}{8}} + 3 =~$" />
        <number-value :value="expr2val" />
      </p>

      <calculation-input
        v-model="inputs.x2"
        class="mb-5"
        prepend-text="$\text{x:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{c)}\hspace{0.2cm} \displaystyle{\frac{8}{x}} + 4 =~$" />
        <number-value :value="expr3val" />
      </p>

      <calculation-input
        v-model="inputs.x3"
        class="mb-5"
        prepend-text="$\text{x:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{d)}\hspace{0.2cm} \displaystyle{\frac{3}{4x}} - 1.5 =~$" />
        <number-value :value="expr4val" />
      </p>

      <calculation-input v-model="inputs.x4" prepend-text="$\text{x:}$" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question351',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        x1: null,
        x2: null,
        x3: null,
        x4: null,
      },
    };
  },
  computed: {
    expr1val() {
      return this.taskState.getValueBySymbol('expr1val').content;
    },
    expr2val() {
      return this.taskState.getValueBySymbol('expr2val').content;
    },
    expr3val() {
      return this.taskState.getValueBySymbol('expr3val').content;
    },
    expr4val() {
      return this.taskState.getValueBySymbol('expr4val').content;
    },
  },
};
</script>
